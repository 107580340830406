<template>
  <b-overlay
    :show="applyOverlay"
    opacity="1"
    :class="applyOverlay ? 'p-2 mb-1 mt-1' : ''"
  >
    <template #overlay>
      <div class="d-flex align-items-center justify-content-center mt-1">
        <b-spinner
          type="border"
          variant="primary"
        />
      </div>
      <div class="d-flex justify-content-center">
        <p class="pt-1">
          {{ $t('loading') }}
        </p>
      </div>
    </template>
    <div v-if="userForm">
      <b-row>
        <b-col
          v-if="!editFormSG"
          sm="12"
        >
          <span class="float-right mt-1">
            {{ $t( 'buttons.edit') }}
            <b-button
              class="btn-icon rounded-circle"
              variant="secondary"
              @click="editFormSG = true"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </span>
        </b-col>
        <b-col
          sm="12"
          class="d-flex justify-content-center"
        >
          <!-- avatar -->
          <b-link
            v-b-modal.modal-camera
            :disabled="mediaStream != null || !editFormSG"
          >
            <b-avatar
              ref="previewEl"
              v-b-tooltip.hover.bottom="editFormSG ? avatarUrl ? $t('generic.updatePhoto') : $t('generic.uploadPhoto') : ''"
              :src="avatarUrl"
              :class="avatarUrl ? 'i-shadow-new': 'bg-dark i-shadow-new' "
              size="135"
            >
              <span v-if="!avatarUrl">+</span>
              <i
                v-if="!avatarUrl"
                class="icon-0-icons-dark-avatar i-icon-size-avatar"
              />
            </b-avatar>
          </b-link>
          <!--/ avatar -->
        </b-col>
        <b-col
          v-if="editFormSG"
          cols="12"
          class="d-flex justify-content-center mt-1"
        >
          <b-link
            v-if="mediaStream == null"
            v-b-modal.modal-camera
            class="text-dark i-link-avatar"
            size="sm"
          >
            {{ avatarUrl ? $t('generic.updatePhoto') : $t('generic.uploadPhoto') }}
          </b-link>
        </b-col>
        <b-col
          v-else
          cols="12"
          class="mt-1"
        />
      </b-row>
      <!-- validation form -->
      <validation-observer ref="formValidation">
        <b-form
          class="mt-2"
          @submit.prevent="saveDataGeneral"
        >
          <b-row>
            <b-col
              sm="6"
              md="4"
            >
              <b-form-group label-for="account-email">
                {{ $t('message.tableHeader.email') }}
                <p class="text-primary">
                  {{ userForm.email }}
                </p>
              </b-form-group>
            </b-col>
            <b-col
              sm="6"
              md="4"
            >
              <b-form-group label-for="account-role">
                {{ $t('roles.rol') }}
                <p class="text-primary">
                  {{ role.name }}
                </p>
              </b-form-group>
            </b-col>
            <template v-if="!checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
              <b-col
                sm="6"
                md="4"
              >
                <b-form-group label-for="account-club">
                  {{ $t('generic.club') }}
                  <p class="text-primary">
                    {{ clubInfo.club_name }}
                  </p>
                </b-form-group>
              </b-col>
              <b-col
                v-if="userForm.jobPosition"
                sm="6"
                md="4"
              >
                <b-form-group label-for="account-job-jobPosition">
                  {{ $t('generic.jobPosition') }}
                  <p
                    v-if="userForm.jobPosition.length"
                    class="text-primary"
                  >
                    {{ userForm.jobPosition[0].name }}
                  </p>
                </b-form-group>
              </b-col>
            </template>
            <b-col
              sm="6"
              md="4"
            >
              <b-form-group label-for="account-job-jobPosition">
                {{ $t('member.form.label.gender') }}
                <p class="text-primary">
                  {{ userForm.gender }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="6"
              md="4"
            >
              <b-form-group label-for="account-name">
                {{ $t('generic.firstName') }}
                <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="userFirstName"
                  rules="required|nameRegex|max:255"
                >
                  <b-form-input
                    id="account-name"
                    v-model="userForm.name"
                    :state="errors.length > 0 ? false : null"
                    name="account-first-name"
                    :placeholder="$t('generic.enterFirstName')"
                    :disabled="!editFormSG"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="6"
              md="4"
            >
              <b-form-group label-for="account-first-name">
                {{ $t('generic.lastName') }}
                <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="userLastName"
                  rules="required|nameRegex|max:255"
                >
                  <b-form-input
                    id="account-first-name"
                    v-model="userForm.firstName"
                    :state="errors.length > 0 ? false : null"
                    name="account-middle-name"
                    :placeholder="$t('generic.enterLastName')"
                    :disabled="!editFormSG"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="6"
              md="4"
            >
              <b-form-group label-for="account-last-name">
                {{ $t('generic.secondLastName') }}
                <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="userSecondLastName"
                  rules="nameRegex|max:255"
                >
                  <b-form-input
                    id="account-last-name"
                    v-model="userForm.lastName"
                    :state="errors.length > 0 ? false : null"
                    name="account-last-name"
                    :placeholder="$t('generic.enterSecondLastName')"
                    :disabled="!editFormSG"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr>
              <b-button
                v-if="editFormSG"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                pill
                variant="primary"
                class="ml-1 float-right"
              >
                {{ $t('buttons.save') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="secondary"
                pill
                class="float-right"
                @click="editFormSG ? reassignAuxForm() : $router.push({ name: 'dashboard-main'})"
              >
                {{ editFormSG ? $t('buttons.cancel') : $t('buttons.back') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <!-- validation form -->
      <!-- modal video via webcam -->
      <b-modal
        id="modal-camera"
        title="Add Photo"
        no-close-on-backdrop
        size="lg"
        @show="startCamera()"
        @hidden="closeModal()"
        @close="closeModal()"
      >
        <!-- stream video -->
        <b-row class="px-1">
          <video
            id="video"
            playsinline
            autoplay
          />
        </b-row>
        <!--/ stream video -->
        <!-- canvas snapshot -->
        <b-row class="px-1 i-display">
          <canvas
            id="canvas"
            width="1280"
            height="720"
            class="bg-success"
          />
        </b-row>
        <!--/ canvas snapshot -->
        <!--/ modal video via webcam -->
        <template #modal-footer>
          <div class="float-right">
            <!-- capture button to canvas web API -->
            <b-button
              class="float-right"
              variant="secondary"
              size="sm"
              @click="getImage"
            >
              <feather-icon
                size="16"
                icon="CameraIcon"
                class="m-30 text-center"
              />+
            </b-button>
            <!--/ capture button to canvas web API -->
          </div>
        </template>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapGetters, mapActions } from 'vuex'
import { BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BLink, BModal, VBModal, BAvatar } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, nameRegex, max } from '@validations'
import checkPermission from '@/auth/permissions'
import { destroyMediaFile, storeMediaFile } from '@/services/mediaFileService'
import userService from '@/services/userService'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BLink,
    BModal,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  data() {
    return {
      auxForm: null,
      avatarUrl: null,
      auxAvatar: null,
      previousFileHash: null,
      applyDeleteFile: false,
      applyOverlay: false,
      editFormSG: false,
      userForm: {
        id: null,
        avatarFile: null,
        avatarFileId: null,
        name: null,
        firstName: null,
        lastName: null,
        gender: null,
        email: null,
        nationalityId: null,
        jobPosition: null,
      },
      mediaStream: null,
      required, // validation
      nameRegex, // validation
      max, // validation
    }
  },

  computed: {
    ...mapGetters({
      role: 'role',
      clubInfo: 'clubInfo',
      incomplete: 'incomplete',
      incompleteByImage: 'incompleteByImage',
      updatedPassword: 'updatedPassword',
      name: 'name',
      path: 'path',
    }),
  },

  mounted() {
    if (this.incompleteByImage) {
      this.showIncompleteDataMessage()
      this.editFormSG = true
    }

    this.fetchUserInfo()
  },

  methods: {
    checkPermission,

    ...mapActions({ setInfo: 'setInfo' }),

    fetchUserInfo() {
      this.applyOverlay = true
      userService.getUserInfo(this.$route.params.id).then(({ data }) => {
        this.userForm = {
          id: data.data.hash,
          avatarFile: data.data.avatar_media_file == null ? null : this.path + data.data.avatar_media_file.url_relative,
          avatarFileId: data.data.avatar_file_hash,
          name: data.data.name,
          firstName: data.data.first_name,
          lastName: data.data.last_name,
          gender: data.data.gender.labelables[0].label,
          email: data.data.email,
          nationalityId: data.data.nationality_id,
          jobPosition: data.data.job_positions.filter(el => el.club_hash == this.clubInfo.hash), // validadr para usuario admin club
        }
        this.previousFileHash = data.data.avatar_media_file == null ? null : data.data.avatar_file_hash
        this.avatarUrl = this.userForm.avatarFile
        this.auxForm = this.userForm
        this.applyOverlay = false
      }).catch(error => {
        this.applyOverlay = false
        this.responseCatch(error)
      })
    },

    reassignAuxForm() {
      this.userForm = JSON.parse(JSON.stringify(this.auxForm))
      this.editFormSG = false
    },

    saveDataGeneral() {
      this.$refs.formValidation.validate().then(success => {
        if (success) {
          const userData = {
            avatar_file: this.userForm.avatarFile,
            avatar_file_id: this.userForm.avatarFileId,
            name: this.userForm.name,
            first_name: this.userForm.firstName,
            last_name: this.userForm.lastName,
          }

          userService.updateUserInfo(this.userForm.id, userData).then(() => {
            this.refreshDataUser()
            this.applyDeleteFile ? this.deleteFileX() : this.showSuccessMessage(true)
          })
          // this.updateUser(this.userForm.id, userData).then(resp => {
          //   if (this.incompleteByImage) {
          //     const params = {
          //       type: 1,
          //     }
          //
          //     this.updateUserAccount(params).then(response => {
          //       this.showSuccessMessage(false)
          //     })
          //   } else {
          //     this.showSuccessMessage(true)
          //   }
          //
          // })
        }
      })
    },

    refreshDataUser() {
      const data = { locale: null }

      userService.getUserAuthenticated(data).then(response => {
        this.setInfo({ user: response.data.data, permissions: null })
      }).catch(error => {
        console.error(error)
      })
    },

    startCamera() {
      if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) { // Validar si el navegador es compatible con la API mediaDevices
        const constraints = {
          audio: false,
          video: {
            width: {
              min: 1280,
              ideal: 1920,
              max: 1920,
            },
            height: {
              min: 720,
              ideal: 1080,
              max: 1080,
            },
            facingMode: 'user', // abrir camara frontal en dispositivos moviles
          },
        }

        // access webcam API mediaDevices
        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
          const video = document.getElementById('video')

          window.stream = stream
          video.srcObject = stream

          this.mediaStream = stream
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Permission denied',
              text: 'Description',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Browser not supported',
            text: 'Your browser does not support this feature',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },

    getImage() {
      const video = document.getElementById('video')
      const canvas = document.getElementById('canvas')

      const context = canvas.getContext('2d')
      const widthImg = context.canvas.width
      const heightImg = context.canvas.height

      context.drawImage(video, 0, 0, widthImg, heightImg)

      const auxAvatarUser = canvas.toDataURL() // obtener el data-URL del canvas (Imagen en base 64)
      this.auxAvatar = this.dataURLtoFile(auxAvatarUser, `${this.name}.png`) // obtener el archivo (object)
      this.useMediaFile()
    },

    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])

      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },

    useMediaFile() {
      const data = new FormData()
      data.append('file', this.auxAvatar)
      data.append('folder', 'users')
      data.append('user_id', this.$route.params.id)

      storeMediaFile(data).then(response => {
        this.avatarUrl = response.data.data.url_absolute
        this.userForm.avatarFile = response.data.data.url_relative
        this.userForm.avatarFileId = response.data.data.hash
        this.applyDeleteFile = true
        this.closeModal()
      }).catch(error => {
        this.closeModal()
      })
    },

    deleteFileX() {
      if (this.previousFileHash != null) {
        destroyMediaFile(this.previousFileHash).then(response => {
          this.showSuccessMessage(true)
        }).catch(error => {
          this.responseCatch(error)
        })
      } else {
        this.showSuccessMessage(true)
      }
    },

    closeModal() {
      if (this.mediaStream != null) {
        this.mediaStream.getTracks().forEach(track => {
          if (track.readyState === 'live' && track.kind === 'video') {
            track.stop()
          }
        })

        setTimeout(() => {
          this.mediaStream = null
        }, 1000)
      }

      this.$bvModal.hide('modal-camera')
    },

    showSuccessMessage(pFlag) {
      this.$swal({
        title: '¡Éxito!',
        text: 'La información se guardo correctamente',
        icon: 'success',
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: `${this.$t('generic.accept')}`,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })

      if (pFlag) {
        this.fetchUserInfo()
        this.editFormSG = false
      } else {
        setTimeout(() => {
          if (this.incomplete) {
            this.$router.push({ name: 'ac-address-and-contact', params: { id: this.$route.params.id } })
          } else if (!this.updatedPassword) {
            this.$router.push({ name: 'ac-change-password', params: { id: this.$route.params.id } })
          } else {
            this.$router.push({ name: 'dashboard-main' })
          }
        }, 1600)
      }
    },

    showIncompleteDataMessage() {
      this.$swal({
        title: '¡No existe foto de perfil!',
        text: 'Para poder continuar es necesario tu foto de perfil.',
        icon: 'warning',
        timer: 5000,
        showConfirmButton: true,
        confirmButtonText: `${this.$t('generic.accept')}`,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style scoped>
.i-icon-size-avatar {
  font-size: 30px;
}

.i-link-avatar:hover {
  font-weight: 600;
}

.i-display {
  display: none;
}
</style>
